<template>
	<div style="width: 100%;height: 100%;">
		<el-container>
		  <el-aside :style="langchange == 'En' ? 'width:300px':'width: 250px !important;'">
		    <ManageList />
		  </el-aside>
		  <el-main>
		    <router-view />
		  </el-main>
		</el-container>
	</div>
</template>

<script>
import ManageList from "@/components/managelist/ManageList";
import * as nav from "@/api/apis";
import bus from '@/utils/bus.js'
export default {
  components: {
    ManageList,
  },
  data(){
	  return{
		  langchange:sessionStorage.getItem('language') ||'zhong',
	  }
  },
  created(){
	  bus.$on('langchange', param => {
      this.langchange = param
    })
  },
	mounted() {
		this.getuser()
	},
	  beforeDestroy () {
    bus.$off('langchange')
  },
	methods:{
		//获取用户信息
		async getuser(){
			let email=JSON.parse(sessionStorage.getItem("user")).email;
			let res=await nav.userId({email:email})
			//console.log(res)
			if(res){
				let data=res.data
				data.password=''
				sessionStorage.setItem("user", JSON.stringify(res.data));
			}
		}
	}
};
</script>

<style lang="less" scoped>
.el-container {
  width: 100%;
  height: 100%;
  .el-main {
    width: 100%;
    padding: 0.16rem 0.18rem;
    background-color: #5c5c5f;
  }
  .el-aside {
    background: url("../../assets/imgs/bglist.png");
  }
}
</style>
