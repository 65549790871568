<template>
  <div>
    <el-menu
      :uniqueOpened="true"
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      background-color="#131715"
      text-color="#B7BAC5"
      active-text-color="#ffd04b"
      router
      @open="openActive"
    >
      <el-submenu
        v-for="(item, index) of managelist"
        :key="index"
        :index="item.id"
      >
        <template #title>
          <!-- <i :class="item.icon"></i> -->
          <span class="spansList">
            <img class="spansListImg" v-if="item.id == '404' && active == item.id" src="../../assets/imgs/menu/shebei.png" alt="">
            <img class="spansListImg" v-if="item.id == '404' && active != item.id" src="../../assets/imgs/menu/shebei2.png" alt="">
            <img class="spansListImg" v-if="item.id == '409' && active == item.id" src="../../assets/imgs/menu/fuwuqi2.png" alt="">
            <img class="spansListImg" v-if="item.id == '409' && active != item.id" src="../../assets/imgs/menu/fuwuqi.png" alt="">
            <img class="spansListImg" v-if="item.id == '413' && active != item.id" src="../../assets/imgs/menu/heyue.png" alt="">
            <img class="spansListImg" v-if="item.id == '413' && active == item.id" src="../../assets/imgs/menu/heyue2.png" alt="">
            <img class="spansListImg" v-if="item.id == '417' && active != item.id" src="../../assets/imgs/menu/meitibaodao.png" alt="">
            <img class="spansListImg" v-if="item.id == '417' && active == item.id"  src="../../assets/imgs/menu/meitibaodao2.png" alt="">
            <img class="spansListImg" v-if="item.id == '419' && active != item.id"  src="../../assets/imgs/menu/juese.png" alt="">
            <img class="spansListImg" v-if="item.id == '419' && active == item.id"  src="../../assets/imgs/menu/juese2.png" alt="">
            <img class="spansListImg" v-if="item.id == '421' && active != item.id"  src="../../assets/imgs/menu/Management.png" alt="">
            <img class="spansListImg" v-if="item.id == '421' && active == item.id"  src="../../assets/imgs/menu/Management2.png" alt="">
            <img class="spansListImg" v-if="item.id == '424' && active != item.id"  src="../../assets/imgs/menu/gongdan.png" alt="">
            <img class="spansListImg"  v-if="item.id == '424' && active == item.id" src="../../assets/imgs/menu/gongdan2.png" alt="">
            <img class="spansListImg" v-if="item.id == '432' && active != item.id"  src="../../assets/imgs/menu/oder2.png" alt="">
            <img class="spansListImg"  v-if="item.id == '432' && active == item.id" src="../../assets/imgs/menu/oder.png" alt="">
            <img class="spansListImg" v-if="item.id == '434' && active != item.id"  src="../../assets/imgs/menu/zhangdan2.png" alt="">
            <img class="spansListImg" v-if="item.id == '434' && active == item.id"  src="../../assets/imgs/menu/zhangdan.png" alt="">
            <img class="spansListImg" v-if="item.id == '437' && active != item.id"  src="../../assets/imgs/menu/taocan2.png" alt="">
            <img class="spansListImg" v-if="item.id == '437' && active == item.id"  src="../../assets/imgs/menu/taocan.png" alt="">
          </span>
          <span v-if="langchange == 'zhong'">{{ item.name |text }}</span>
          <span v-if="langchange == 'En'">{{ item.languages }}</span>
          <span class="spanmess" v-if="item.id == '424' && statusMg == 0">
            <div class="spanmess_mes"></div>
          </span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="(items, index) of item.children"
            :key="index"
            :index="items.path"
            >
            <!-- {{ items.name }} -->
            <span v-if="langchange == 'zhong'">{{ items.name |text }}</span>
          <span v-if="langchange == 'En'">{{ items.languages }}</span>
            <span class="spanmess" v-if="items.id == '425' && statusMg == 0">
              <div class="spanmess_mes"></div>
            </span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import * as admin from "@/api/roleList.js";
import bus from '@/utils/bus.js'
export default {
  data() {
    return {
      managelist: [],
      userid: "",
      statusMg: 1,
      initPrivsList: [],
      timer:'',
      active:"404",
      langchange:sessionStorage.getItem('language') ||'zhong',
    };
  },
  created() {
    this.initPriv();
    this.userid = JSON.parse(sessionStorage.getItem("user")).id;
    if(sessionStorage.getItem("activeManag")){
      this.active = sessionStorage.getItem("activeManag")
    }
    this.getlist();
    this.getReadStatus();
    bus.$on('langchange', param => {
      this.langchange = param
      console.log(param)
    })
  },
  beforeDestroy () {
    bus.$off('langchange')
  },
	filters:{
		text(val){
			let zhon = sessionStorage.getItem("language");
			//console.log(zhon)
			let text=''
			if(val){
				let sk=val
				if(zhon=='zhong'){
					let wq=sk.split('-')
					sk=wq[0]
				}else{
					let wq=sk.split('-')
					sk=wq[1]
					if(wq.length==1){
						sk=wq[0]
					}
				}
				text=sk
			}
			return text
		}
	},
  mounted() {
    this.timer = setInterval(() => {
      this.getReadStatus();
    }, 6000);
    this.$once('hook:beforeDestroy', ()=>{
    clearInterval(this.timer)
  })
  },
  methods: {
    openActive(e){
      console.log(e)
      this.active = e
      sessionStorage.setItem("activeManag", e);
    },
    //获取全部权限
    async initPriv() {
      let res = await admin.initPrivs();
      if (res) {
        let arrs = [];
        res.forEach((item) => {
          item.children = [];
          if (item.id && !item.parentId) {
            arrs.push(item);
          }
        });
        arrs.forEach((item) => {
          res.forEach((ims) => {
            if (item.id === ims.parentId) {
              item.children.push(ims);
            }
          });
        });
        console.log(arrs)
        this.initPrivsList = arrs;
      }
    },
    //获取管理员隶属的角色
    async getlist() {
      let res = await admin.admin({ id: this.userid });
      //console.log(res)
      this.jurisdiction(res.id);
    },
    //获取工单消息提示
    async getReadStatus() {
      let res = await admin.getReadStatus();
      // console.log(res.data)
      this.statusMg = res.data;
    },
    //获取当前角色拥有的权限
    async jurisdiction(id) {
      let res = await admin.initPrivs_a({ role_id: id });
      //console.log(res)
      let arrs = [];
      this.initPrivsList.forEach((item) => {
        res.forEach((ims) => {
          if (ims.parentId === item.id) {
            item.children = [];
            arrs.push(item);
          }
        });
      });
      let arr1 = this.unique(arrs);
      arr1.forEach((item) => {
        res.forEach((ims) => {
          if (item.id === ims.parentId) {
            ims.path = "/nav/manage/" + ims.path;
            item.children.push(ims);
          }
        });
      });
      arr1.forEach((item) => {
        item.id = item.id + "";
      });
      this.managelist = arr1;
      //this.managelists=arr1[0].children[0].path
      //console.log(arr1)
      //this.$router.push(`/nav/manage/${this.managelists}`)
      console.log(this.managelist)
    },
    //去重数组
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    },
  },
   beforeDestroy() {
      clearInterval(this.timer);
    }
};
</script>
<style>
.el-submenu__title {
  font-size: 18px !important;
}
.el-menu-item {
  font-size: 16px !important;
}
</style>
<style lang="less" scoped>
.el-menu {
  border: none;
}
.spanmess {
  position: relative;
}
.spanmess_mes {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: red;
  position: absolute;
  right: -18px;
  top: 2px;
  border-radius: 50%;
}
.spansList{
  display: inline-block;
      width: 18px;
    height: 15px;
    padding-right: 10px;
}
.spansListImg{
  width: 100%;
  height: 100%;
}
.el-submenu__title *{
      vertical-align: inherit;
}
</style>
